import { Component, OnInit } from '@angular/core';
import { ChatBotFacade } from 'src/app/shared/facades/chat-bot.facade';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent implements OnInit {

  constructor(
    public chatBotFacade: ChatBotFacade,
  ) { }

  ngOnInit(): void {
    if(this.chatBotFacade.isRunning())
      this.chatBotFacade.destroy();
  }
}
