import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor() { }

  getTokenApi() {
    const token = JSON.parse(sessionStorage.getItem('usuarioLogado')).token.acessToken;
    return token;
  }

}
