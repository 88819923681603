<div class="Page PagePAT">
  <div class="PAT_Content">
    <div class="PAT_Header">
      <h1 hidden id="titlePAT">PAT #{{ password }}</h1>
    </div>

    <div class="PAT_Body">
      <table hidden id="tableConteudo">
        <thead>
          <th>Informações</th>
        </thead>
      </table>

      <table hidden id="tableAnexos">
        <thead>
          <th>Anexos</th>
        </thead>
      </table>
    </div>
  </div>
</div>

<app-text-input-with-confirmation id="text-input-with-confirmation" className="__is-open">
</app-text-input-with-confirmation>

<app-ligthboxes-default id="custom-generico" className="__is-open">
</app-ligthboxes-default>
