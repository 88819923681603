import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ds-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CollapseComponent implements OnInit {
  @Input('p-is-open') isCollapseOpen: boolean = false;
  @Input('p-title') title: string;
  @Input('p-icon') icon: string;

  constructor() { }

  ngOnInit() {}
}