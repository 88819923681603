import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { LightBoxesService } from '../lightboxes';
import { Router } from '@angular/router';
import { ChatBotFacade } from '../../facades/chat-bot.facade';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dados-header',
  templateUrl: './dados-header.component.html',
  styleUrls: ['./dados-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DadosHeaderComponent implements OnInit, OnDestroy {
  public isCallCenterPopupOpen: boolean = false;
  public subscription: Subscription;

  constructor(
    private lightBoxesService: LightBoxesService,
    private router: Router,
    private chatBotFacade: ChatBotFacade,
  ) { }

  ngOnInit() {
    this.subscription = this.chatBotFacade.onOpen().subscribe(() => {
      this.isCallCenterPopupOpen = false;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async sairSimOuNao() {
    const userChoice = await this.openExitConfirmationPopup();

    if(userChoice)
      this.sair();
  }

  private sair() {
    sessionStorage.clear();
    this.router.navigate(['/']);
    return;
  }

  private openExitConfirmationPopup(): Promise<boolean> {
    return this.openYesOrNoPopup('Sair', 'Tem certeza que quer sair?');
  }

  private openYesOrNoPopup(title: string, text: string) {
    const id = 'custom-mural-cadastrado';

    return this.lightBoxesService.open(id, { title, text }, 0);
  }
}