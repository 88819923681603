import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ds-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true,
  }],
})
export class CheckboxComponent implements ControlValueAccessor {
  public checkboxValue: boolean = false;

  public onChangeCallback: (value: any) => void;
  public onTouchedCallback: (value: any) => void;

  @Input('p-label') label: string;
  @Input('p-id') id: string;
  
  public writeValue(newValue: boolean) {
    this.checkboxValue = newValue;
  }

  public registerOnChange(onChangeCallback: any): void {
    this.onChangeCallback = onChangeCallback;
  }

  public registerOnTouched(onTouchedCallback: any): void {
    this.onTouchedCallback = onTouchedCallback;
  }

  // Sempre que o valor mudar, é necessário executar o onChange
  // para que o angular atualize o valor no formControl.
  toggleValue() {
    this.checkboxValue = !this.checkboxValue;

    this.onChangeCallback(this.checkboxValue)
  }
}
