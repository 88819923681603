import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { moduleChangeAnimation } from 'src/app/shared/animations/moduleChange';
import { ChatBotFacade } from 'src/app/shared/facades/chat-bot.facade';

@Component({
  selector: 'app-header-and-sidebar-layout',
  templateUrl: './header-and-sidebar-layout.component.html',
  styleUrls: ['./header-and-sidebar-layout.component.scss'],
  animations: [moduleChangeAnimation],
})
export class HeaderAndSidebarLayoutComponent {

  constructor(
    private chatBoxFacade: ChatBotFacade
  ) {
    if(!this.chatBoxFacade.isRunning())
      this.chatBoxFacade.init();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.isActivated;
  }
}
