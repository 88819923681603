<div class="Lightbox">
  <div class="Popup Popup_ComunicacaoGenerica">
    <div class="Popup_Header">
      <div class="Popup_Header_Title">Titulo genérico</div>
    </div>
    <div class="Popup_Content">
      <div class="Popup_Content_MsgDefault">
        Aqui entrará qualquer texto
      </div>
    </div>

    <div class="FormGroup">
      <div class="FormGroup_Input">
        <input type="text" [formControl]="textInputValue">
      </div>
    </div>


    <div class="Popup_Footer">
      <div class="Popup_Footer_Buttons">
        <ds-button type="Outline" full="true" (click)="close()">
          Cancelar
        </ds-button>

        <ds-button full="true" (click)="confirmar()">
          Enviar
        </ds-button>
      </div>
    </div>
  </div>
</div>
