import { Component, OnInit, ViewEncapsulation, Input, ChangeDetectionStrategy } from '@angular/core';
import { MenuDTO, FuncionalidadesMenuDTO } from '../../DTO/response/menuDTO';
import { Router } from '@angular/router';
import { LightBoxesService } from '../lightboxes';
import { SidebarFacade } from './sidebar.facade';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
  public isTestMenuOpen: boolean = true;
  public isSubItemTestOpen: boolean = false;
  public usuarioLogado;
  public codigoMenuAberto;
  public statusbloqueados = [304, ""]
  public menus$ = new Observable<MenuDTO[]>();

  constructor(
    public sidebarFacade: SidebarFacade,
    public router: Router,
    private lightboxesService: LightBoxesService
  ) { }

  ngOnInit(): void {
    this.fetchData();

    this.menus$ = this.sidebarFacade.getAllMenus();
    this.usuarioLogado = JSON.parse(sessionStorage.getItem('usuarioLogado'));
    this.codigoMenuAberto = JSON.parse(sessionStorage.getItem('codigoMenu'));
  }
  
  fetchData() {
    this.sidebarFacade.fetchAllMenusIfUnset();
  }

  toggleSidebarState() {
    this.sidebarFacade.toggle();
  }

  get isOpen() {
    return this.sidebarFacade.isOpen();
  }

  get isClosed() {
    return this.sidebarFacade.isClosed();
  }

  toggleSubMenu(codigoMenu: number) {
    document.getElementById(codigoMenu.toString()).classList.toggle("__is-open");
    sessionStorage.setItem('codigoMenu', JSON.stringify(codigoMenu))
  }

  async setInstrucoes(submenu: FuncionalidadesMenuDTO) {
   if (this.isInaccessibleMenu(submenu) && !this.estaComReeenvioAnalise(submenu)) {
      await this.openInaccessibleMenuAdvicePopup();
      return;
    }

    if(this.estaConcluida(submenu)) {
      await this.openStepCompletedMenuAdvicePopup();
      return
    }

    if (this.estaComReeenvioAnalise(submenu)) {
      await this.openInaccessibleMenuAdvicePopup_Reenvio();
      return;
    }

      


    if (!this.statusbloqueados.includes(submenu.status)) {
      this.sidebarFacade.setCurrentMenu(submenu);
      sessionStorage.setItem('instrucoes', submenu.instrucao);
      sessionStorage.setItem('status', submenu.status.toString());
      sessionStorage.setItem('descricaoStatus', submenu.descricaoStatus);
      sessionStorage.setItem('botaoNaoSeAplica', JSON.stringify(submenu.apresentarBotaoNaoSeAplica));
      sessionStorage.setItem('codigoPATselecionado', submenu.codigoPAT);

      this.router.navigate([submenu.rota]);

      if(window.innerWidth < 800)
        this.sidebarFacade.close();
    }
  }

  getSubmenuIconClass(submenu: FuncionalidadesMenuDTO) {
    return {
      'fa-exclamation __is-red': (submenu.status == 306), 
      'fa-pen __is-blue': (submenu.status == 0),
      'fa-sync-alt __is-Analise': this.estaEmAnalise(submenu), 
      'fa-check __is-green': submenu.status == 308
    }
  }

  private isInaccessibleMenu(feature: FuncionalidadesMenuDTO): boolean {
    const statusCode = feature.status;

    return statusCode === 307 || statusCode === 304
  }

  private estaEmAnalise(menu: FuncionalidadesMenuDTO) {
    const statusCode = menu.status;

    return statusCode == 304 || statusCode == 324 || statusCode == 307
  }
  
  private estaConcluida(menu: FuncionalidadesMenuDTO) {
    const statusCode = menu.status;
    return statusCode == 308
  }

  private estaComReeenvioAnalise(menu: FuncionalidadesMenuDTO) {
    const statusCode = menu.status;
    return statusCode == 307
  }

  private async openInaccessibleMenuAdvicePopup() {
    await this.openOkPopup(
      'Atenção',
      `Esta etapa está em análise pela APSA e
      não é possível alterar as informações
      enviadas, por favor aguarde.`
    );
  }
  
  private async openStepCompletedMenuAdvicePopup() {
    await this.openOkPopup(
      'Etapa concluída',
      `Esta etapa já foi validada e finalizada pela APSA e não é possível alterar as informações. Se precisar corrigir alguma informação ou precisar de ajuda, entre em contato pelos nossos canais de atendimento.`
    );
  }

  private async openInaccessibleMenuAdvicePopup_Reenvio() {
    await this.openOkPopup(
      'Reenvio em análise',
      `Esta etapa está com o reenvio em análise. Aguarde até que os novos dados sejam validados pela APSA. Para dúvidas ou ajuda, entre em contato pelos nossos canais de atendimento.`
    );
  }

  private openOkPopup(title: string, text: string) {
      return this.lightboxesService.open('custom-generico', { title, text }, 20_000);
  }
}
