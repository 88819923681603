import { LightBoxesService } from 'src/app/shared/components/lightboxes';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SendMessageService } from '../../services/send-email-service';
import { AssuntoEnvioEmailDTO } from '../../DTO/response/AssuntoEnvioEmailDTO';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss'],
})
export class SendMessageComponent implements OnInit {
  @Output() onBack = new EventEmitter();

  public listaAssuntos: AssuntoEnvioEmailDTO[] = [];
  public form: FormGroup;
  public isSubmitted: boolean = false;
  public erroMessage = 'Este campo é obrigatório.';

  constructor(
    private fb: FormBuilder,
    private lightboxesService: LightBoxesService,
    private sendEmailService: SendMessageService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getAssuntos();
  }

  getAssuntos() {
    this.sendEmailService.buscarMenuDescricao().subscribe((obj) => {
      if (obj) this.listaAssuntos = obj;
    });
  }

  createForm() {
    this.form = this.fb.group({
      type: ['', [Validators.required]],
      message: ['', [Validators.required, Validators.minLength(20)]],
    });
  }

  get formControls() {
    return this.form.controls;
  }

  emitBack() {
    this.onBack.emit();
  }

  setErrorClass(formControlName: string) {
    const formControl = this.form.get(formControlName);

    if (formControlName == 'message') this.messageErro();

      return { '__is-error': this.isError(formControl) };
  }

  handleSubmit() {
    console.log(this.form.controls.type.value);
    console.log(this.form.controls.message.value);
    console.log('Submiting...');

    var usuario = JSON.parse(sessionStorage.getItem('usuarioLogado'));
    if (!usuario) return;

    let obj = {
      idEtapa: this.form.controls.type.value,
      mensagem: this.form.controls.message.value,
      nomePessoa: usuario.nome,
      nomeCondominio: usuario.nomeCondominio,
      contaCondominio: usuario.contaCondominio,
      emailPessoa: usuario.email,
    };

    this.sendEmailService.enviarMensagem(obj).subscribe(
      (item) => {
        this.mensagemEnviadaComSucesso();
      },
      (err) => {
        this.mensagemErroAoEnviar();
        console.log('erro', err);
      }
    );
  }

  private isError(formControl: AbstractControl) {
    return formControl.invalid && this.isSubmitted;
  }

  private messageErro() {
    const valor = this.formControls.message.value;
    if (valor && valor.length < 20) this.erroMessage = 'A mensagem precisa ter pelo menos 20 caracteres.'
    else this.erroMessage = 'Este campo é obrigatório.';
  }

  private async mensagemEnviadaComSucesso() {
    await this.openOkPopup('Título', `Mensagem enviada com sucesso.`);
  }

  private async mensagemErroAoEnviar() {
    await this.openOkPopup('Atenção', `Erro ao enviar mensagem.`);
  }

  private openOkPopup(title: string, text: string) {
    return this.lightboxesService.open(
      'custom-generico',
      { title, text },
      20_000
    );
  }
}
