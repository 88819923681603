import { Injectable } from "@angular/core";
import { LightBoxesService } from '../components/lightboxes';
import { SidebarState } from '../components/sidebar/sidebar.state';
import { Router } from '@angular/router';
import { FuncionalidadesMenuDTO } from '../DTO/response/menuDTO';

@Injectable()
export class MenuBusiness {

  constructor(
    private lightboxesService: LightBoxesService,
    private sidebarState: SidebarState,
    private router: Router,
  ) {  }

  isForbiddenAccess(route: string): boolean {
    const currentMenu  = this.sidebarState.findMenuByRoute(route);

    return currentMenu ? this.isInacessibleStatus(currentMenu.status) : false;
  }

  routeToNextStepOrHome() {
    const submenus = this.getAccessibleSubmenus();

    if(submenus.length === 0)
      this.router.navigate(['/home']);
    else
      this.calculateNextStepRouteAndNavigateToIt();
  }

  getAccessibleSubmenus(): FuncionalidadesMenuDTO[] {
    return this.sidebarState.getSubMenusWithStatus(0);
  }

  isAllMenusBeingAnalyzed(): boolean {
    return this.sidebarState.isAllMenusWithSameStatus(304);
  }

  handleForbiddenAccess() {
    this.router.navigate(['/home']);
    window.setTimeout(() => this.adviceForbiddenAccess(), 3_000);
  }

  private adviceForbiddenAccess() {
    this.lightboxesService.open('custom-generico', {
      title: 'Atenção',
      text: 'Você não tem permissão para acessar esta página. Por favor aguarde até que etapa seja analisada pela APSA.'
    }, 100_000);
  }

  private isInacessibleStatus(status: number): boolean {
    return status === 307 || status === 304 || status === 308;
  }

  private calculateNextStepRouteAndNavigateToIt() {
    const submenus = this.sidebarState.getCurrentSubMenuAndTheOnesWithStatus(0);
    const currentMenu = this.sidebarState.getCurrentMenu();
    const currentMenuIndex = submenus.findIndex(s => s.rota === currentMenu.rota);
    const nextSubMenuPosition = (currentMenuIndex + 1) % submenus.length;

    const nextSubMenu = submenus[nextSubMenuPosition];

    this.setStorage(nextSubMenu);

    this.sidebarState.setCurrentMenu(nextSubMenu);
    this.router.navigate([nextSubMenu.rota]);
  }

  private setStorage(func: FuncionalidadesMenuDTO) {
    sessionStorage.setItem('instrucoes', func.instrucao)
    sessionStorage.setItem('status', func.status.toString())
    sessionStorage.setItem('descricao', func.descricaoStatus)
    sessionStorage.setItem('submenu', JSON.stringify(func))
    sessionStorage.setItem('botaoNaoSeAplica', JSON.stringify(func.apresentarBotaoNaoSeAplica))
    sessionStorage.setItem('codigoPATselecionado', func.codigoPAT);
    this.activeUpperMenu(func);
  }

  private activeUpperMenu(func: FuncionalidadesMenuDTO) {
    const menus = this.sidebarState.getAllMenusValue();

    for(let i = 0; i < menus.length; i++) {
      if(menus[i].funcionalidades.includes(func)) {
        sessionStorage.setItem('codigoMenu', JSON.stringify(menus[i].codigoMenu));
        break;
      }
    }
  }
}