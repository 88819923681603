import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

declare const ZenviaChat: any;

const KEY: string = 'eea41d6e97c56ed3d409674fb91fb936';

@Injectable({ providedIn: 'root' })
export class ChatBotFacade {
  private chat: any = null;
  private openEventEmitter = new Subject<void>();
  
  init() {
    const usuarioLogado = JSON.parse(sessionStorage.getItem('usuarioLogado'));
    const contaCondominio = sessionStorage.getItem('contaCondominio');

    this.chat = new ZenviaChat(KEY)
      .extraData({
        nome: usuarioLogado.nome + " - COND: " + contaCondominio,
        email: usuarioLogado.email,
        condominio: usuarioLogado.login,
        telefone: usuarioLogado.telefone,
        cpf: usuarioLogado.cpf
      })
      .embedded('buttonless')
      .build();
  }

  open() {
    this.chat.open();
    this.openEventEmitter.next();
  }

  close() {
    this.chat.close();
  }

  onOpen(): Observable<void> {
    return this.openEventEmitter.asObservable();
  }

  destroy() {
    this.chat.destroy();
    this.chat = null;
  }

  isRunning() {
    return this.chat !== null;
  }
}