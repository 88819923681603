<div class="HeaderActionsContainer">
    <div class="HeaderActionsContainer">
      <div class="HeaderAction" [ngClass]="{'__is-open': isCallCenterPopupOpen}">
        <div
          class="HeaderActionIcon Central-Div"
          (click)="isCallCenterPopupOpen = !isCallCenterPopupOpen"
          title="Central de atendimento"
        >
          <i class="apsa-icon-outline-mail Item_Content_Icone __is-mail-letter"></i>
        </div>

        <div class="HeaderPopup __is-left" *ngIf="isCallCenterPopupOpen">
          <div class="PopupCloseContainer">
            <i class="fas fa-times" (click)="isCallCenterPopupOpen = false"></i>
          </div>

          <app-central-de-atendimento></app-central-de-atendimento>
        </div>
      </div>

      <div class="HeaderAction Exit-Div" (click)="sairSimOuNao()" title="Sair">
        <div class="HeaderActionIcon Header_Exit_Icon">
          <i class="apsa-icon-outline-logout"></i>
        </div>

        <div class="Text-Exit-Mobile">Sair</div>
      </div>
    </div>
  </div>