<button
  class="Button Button_{{color}} Button_{{type}}"
  [ngClass]="{
   '__is-full': full,
   '__is-inactive': inactive,
   '__is-small': small
  }"
>
  <div class="Button_Content">
    <div *ngIf="leftIcon" class="Button_Content_Icon">
      <i class="{{ leftIcon }}"></i>
    </div>

    <div class="Button_Content_Label">
      <ng-content></ng-content>
    </div>

    <div *ngIf="rightIcon" class="Button_Content_Icon">
      <i class="{{ rightIcon }}"></i>
    </div>
  </div>
</button>