import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { CollapseComponent } from './components/collapse/collapse.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CardComponent } from './components/card/card.component';

@NgModule({
  declarations: [
    ButtonComponent,
    CheckboxComponent,
    CollapseComponent,
    CardComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  exports: [
    ButtonComponent,
    CheckboxComponent,
    CollapseComponent,
    CardComponent,
  ]
})
export class DesignSystemModule { }
