import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { HeaderAndSidebarLayoutComponent } from './header-and-sibebar-layout/header-and-sidebar-layout.component';
import { RouterModule } from '@angular/router';
import { LoginLayoutComponent } from './login-layout/login-layout.component';

@NgModule({
  declarations: [
    HeaderAndSidebarLayoutComponent,
    LoginLayoutComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
  ]
})
export class ContainersModule { }
