<div class="atendimento-component">
  <ng-container *ngIf="!enviarMensagem; else enviarMensagemEtapa">
    <h1 class="atendimento-component__title">
      Central de atendimento
    </h1>
  
    <div class="atendimento-component__menu">
      <div class="menu-item" (click)="handleChatOnlineClick()">
        <div class="menu-item__content">
          <div class="menu-item__icon">
            <i class="fab fa-rocketchat"></i>
          </div>
  
          <div class="menu-item__label">
            Chat On-Line
          </div>
        </div>
      </div>
  
      <div class="menu-item">
        <div class="menu-item__content">
          <div class="menu-item__icon">
            <i class="fab fa-whatsapp"></i>
          </div>
  
          <div class="menu-item__label">
            Atendimento via Whatsapp
          </div>
        </div>
      </div>
  
      <div class="menu-item" (click)="handleEnviarUmaMensagemClick()">
        <div class="menu-item__content">
          <div class="menu-item__icon">
            <i class="far fa-comments"></i>
          </div>
  
          <div class=" menu-item__label">
            Enviar uma mensagem
          </div>
        </div>
      </div>
  
      <div class="menu-item">
        <div class="menu-item__content __is-contact">
          <div class="menu-item__label">
            Telefones úteis
          </div>
  
          <div class="menu-item__description">
            Encontre o telefone da central de atendimento
            que está procurando:
          </div>
  
          <div class="menu-item__description __is-highlighted">
            55 21 0000 0000
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #enviarMensagemEtapa>
    <app-send-message
      (onBack)="handleAtendimentoBack()"
    ></app-send-message>
  </ng-template>
</div>