<div class="send-message-component">
  <h1 class="title">Enviar uma mensagem</h1>

  <form class="send-message__form" [formGroup]="form">
    <div class="FormGroup" [ngClass]="setErrorClass('type')">
      <div class="FormGroup_Label">Tipo de mensagem</div>

      <div class="FormGroup_Input">
        <select formControlName="type">
          <option
            *ngFor="let assunto of this.listaAssuntos"
            value="{{ assunto.id }}"
          >
            {{ assunto.descricao }}
          </option>
        </select>
      </div>

      <div class="FormGroup_Erro">Este campo é obrigatório.</div>
    </div>

    <div class="FormGroup" [ngClass]="setErrorClass('message')">
      <div class="FormGroup_Label">Mensagem</div>

      <div class="FormGroup_Input">
        <textarea
          formControlName="message"
          placeholder="Escreva aqui a sua mensagem"
        ></textarea>
      </div>

      <div class="FormGroup_Erro">{{erroMessage}}</div>
    </div>

    <ds-button
      (click)="this.isSubmitted = true; !form.invalid && handleSubmit()"
      [inactive]="form.invalid"
      full="true"
      leftIcon="fas fa-check"
    >
      Enviar
    </ds-button>
  </form>

  <ds-button
    (click)="emitBack()"
    type="Outline"
    full="true"
    leftIcon="fas fa-chevron-left"
  >
    Voltar
  </ds-button>
</div>
