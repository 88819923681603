<div class="ApplicationContainer">
  <div class="SidebarAndPageContentContainer">
    <app-sidebar></app-sidebar>

    <div class="PageContentContainer">
      <app-header></app-header>

      <div
        class="ModuleContainer"
        [@routeAnimations]="prepareRoute(outlet)"
        #moduleContainer
        id="module-container"
      >
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>
  </div>
</div>

<app-ligthboxes-default id="custom-generico" className="__is-open">
</app-ligthboxes-default>

<app-mural-cadastrado id="custom-mural-cadastrado" className="__is-open">
</app-mural-cadastrado>

<app-text-input-with-confirmation id="text-input-with-confirmation" className="__is-open">
</app-text-input-with-confirmation>

<app-image-with-toggle-and-ok-button id="image-with-toggle-and-ok-button" className="__is-open">
</app-image-with-toggle-and-ok-button>

<app-modal-user-menu></app-modal-user-menu>