import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FuncionalidadesMenuDTO, MenuDTO } from '../../DTO/response/menuDTO';
import { ActivatedRoute } from '@angular/router';
import { MenuBusiness } from '../../business/menu.business';

const CURRENT_MENU_KEY = 'submenu';

@Injectable({
  providedIn: 'root'
})
export class SidebarState implements OnDestroy {
  public isSidebarOpen: BehaviorSubject<boolean>;
  public currentMenu = new BehaviorSubject<FuncionalidadesMenuDTO>(null);
  public allMenus = new BehaviorSubject<MenuDTO[]>([]);
  public updating = new BehaviorSubject<boolean>(false);

  constructor(private activatedRouter: ActivatedRoute) {
    this.setIsOpen();
    this.setCurrentMenu(this.getCurrentMenuFromSessionStorage());
  }

  ngOnDestroy() {
    this.clearCurrentMenu();
  }

  toggle() {
    this.isSidebarOpen.next(!this.isSidebarOpen.value);
  }

  open() {
    this.isSidebarOpen.next(true);
  }

  close() {
    this.isSidebarOpen.next(false);
  }

  reset() {
    this.isSidebarOpen.next(true);
    this.allMenus.next([]);
    this.clearCurrentMenu();
  }

  findMenuByRoute(route: string): FuncionalidadesMenuDTO | null {
    const menus = this.allMenus.value;

    for(let i = 0; i < menus.length; i++)
      for(let j = 0; j < menus[i].funcionalidades.length; j++)
        if(menus[i].funcionalidades[j].rota === route)
          return menus[i].funcionalidades[j];

    return null;
  }

  getSubMenusWithStatus(statusID: number): FuncionalidadesMenuDTO[] {
    const submenus: FuncionalidadesMenuDTO[] = [];
    const menus = this.getAllMenusValue();

    menus.forEach(menu => {
      submenus.push(
        ...menu.funcionalidades.filter(submenu => submenu.status === statusID)
      );
    });
    return submenus;
  }

  getCurrentSubMenuAndTheOnesWithStatus(statusID: number): FuncionalidadesMenuDTO[] {
    const currentMenu = this.getCurrentMenu();
    const allMenus = this.getAllMenusValue();
    const submenus: FuncionalidadesMenuDTO[] = [];

    if(!currentMenu)
      return this.getSubMenusWithStatus(statusID);

    allMenus.forEach(menu => {
      submenus.push(
        ...menu.funcionalidades.filter(submenu => {
          const status = submenu.status;
          const rota = submenu.rota;

          return status === statusID || rota === currentMenu.rota
        })
      )
    });

    return submenus
  }

  setAllMenus(menus: MenuDTO[]) {
    this.allMenus.next(menus);
  }

  getAllMenus(): Observable<MenuDTO[]> {
    return this.allMenus.asObservable();
  }

  getAllMenusValue(): MenuDTO[] {
    return this.allMenus.value;
  }

  setCurrentMenu(menu: FuncionalidadesMenuDTO) {
    this.currentMenu.next(menu);
    this.storeCurrentMenuInSessionStorage();
  }

  clearCurrentMenu() {
    this.currentMenu.next(null);
    this.removeCurrentMenuFromSessionStorage();
  }

  getCurrentMenuSubject(): BehaviorSubject<FuncionalidadesMenuDTO> {
    return this.currentMenu;
  }

  getCurrentMenu(): FuncionalidadesMenuDTO {
    return this.currentMenu.value;
  }
    
  isOpen(): boolean {
    return this.isSidebarOpen.value;
  }

  isClosed(): boolean {
    return !this.isSidebarOpen.value;
  }

  getIsOpen(): BehaviorSubject<boolean> {
    return this.isSidebarOpen;
  }

  isEmpty(): boolean {
    return this.allMenus.value.length === 0;
  }

  setUpdating(updating: boolean) {
    this.updating.next(updating);
  }

  isUpdating(): Observable<boolean> {
    return this.updating.asObservable();
  }

  isAllMenusWithSameStatus(statusID: number): boolean {
    const menus = this.allMenus.value;
        
    if(!menus || menus.length === 0)
      return false;

    for(let i = 0; i < menus.length; i++)
      for(let j = 0; j < menus[i].funcionalidades.length; j++)
        if(menus[i].funcionalidades[j].status !== statusID)
          return false;

    return true;
  }
  
  private getCurrentMenuFromSessionStorage(): FuncionalidadesMenuDTO {
    const menuStringfied = sessionStorage.getItem(CURRENT_MENU_KEY);

    return ((menuStringfied) ? JSON.parse(menuStringfied) : null);
  }

  private storeCurrentMenuInSessionStorage() {
    const menu = this.currentMenu.value;

    sessionStorage.setItem(CURRENT_MENU_KEY, JSON.stringify(menu));
  }

  private removeCurrentMenuFromSessionStorage() {
    sessionStorage.removeItem(CURRENT_MENU_KEY);
  }

  private setIsOpen() {
    if(window.innerWidth > 800) {
      this.isSidebarOpen = new BehaviorSubject(true);
    }
    else {
      this.isSidebarOpen = new BehaviorSubject(false);
    }
  }
}
