import { Component, OnInit, Input, ElementRef } from "@angular/core";
import { LightBoxesService } from "../lightboxes.services";
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-image-with-toggle-and-ok-button',
  templateUrl: './image-with-toggle-and-ok-button.component.html',
  styleUrls: ['./image-with-toggle-and-ok-button.component.scss'],
})
export class ImageWithToggleAndOkButton implements OnInit {
  @Input() id: string;
  @Input() className: string;
  private element: any;
  private promisse: Promise<boolean>;
  private resolve;
  // private toggle = new FormControl(false);
  private url: string;

  constructor(private service: LightBoxesService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener("click", el => {
      if (el.target.className === "Lightbox") {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.service.add(this);
  }

  ngOnDestroy(): void {
    // TODO: Retirado para não bugar
    // this.service.remove(this.id);
    this.element.remove();
  }

  abrirLink() {
    if (this.url) {
      window.open(this.url, '_blank')
    }
  }


  open(mensagem: { title: string, imgPath: string, toggleLabel: string, url: string }, time: number = 1000): Promise<boolean> {
    const n = this.className;
    const a = this.element;
    this.element.firstChild.classList.add(this.className);
    this.element.getElementsByClassName('Popup_Header_Title')[0].innerHTML = mensagem.title;
    //this.element.getElementsByClassName('FormGroupToggle_Label')[0].innerHTML = mensagem.toggleLabel;
    this.element.getElementsByClassName('LightboxImage')[0].setAttribute('src', mensagem.imgPath);
    this.url = mensagem.url;
    //this.element.getElementsByClassName('Popup Popup_ComunicacaoGenerica')[0].classList.add(this.className);

    if (time === 0) return new Promise(resolve => { this.resolve = resolve; });

    return new Promise(resolve => {
      this.resolve = resolve;

      setTimeout(function () {
        a.firstChild.classList.remove(n);
        //this.element.getElementsByClassName('Popup Popup_ComunicacaoGenerica')[0].classList.remove(this.className);
        resolve(false);
      }, time);
    });
  }

  confirmar(): void {
    // this.resolve(this.toggle.value);
    this.close();
  }

  close(): void {
    this.resolve(false);
    this.element.firstChild.classList.remove(this.className);
  }
}
