import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  HeaderAndSidebarLayoutComponent
} from './containers/header-and-sibebar-layout/header-and-sidebar-layout.component';
import { LoginLayoutComponent } from './containers/login-layout/login-layout.component';
import { IndexComponent } from './modules/xml/pages/index/index.component';
import { ForbiddenPageStatusInterceptor } from './shared/interceptors/forbidden-page-status.interceptor';

const routes: Routes = [
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/login/login.module').then(m => m.LoginModule)
      },
    ],
    data: { animation: 'LoginPage' },
  },
  {
    path: '',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/home/home.module').then(m => m.HomeModule)
      },
    ],
    data: { animation: 'HomePage' },
  },
  {
    path: 'pat',
    component: IndexComponent,
    children: [
      {
        path: 'pat',
        loadChildren: () =>
          import('./modules/xml/xml.module').then(m => m.XmlModule)
      },
    ],
  },
  {
    path: 'formulario-generico',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/formulario-generico/formulario-generico.module').then(m => m.FormularioGenericoModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'dados-condominio',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/dados-condominio/dados-condominio.module').then(m => m.DadosCondominioModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor],
  },
  {
    path: 'gestores',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/dados-gestores/dados-gestores.module').then(m => m.DadosGestoresModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'condicoes-contratuais',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/condicoes-contratuais/condicoes-contratuais.module').then(m => m.CondicoesContratuaisModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'receitas',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/receitas/receitas.module').then(m => m.ReceitasModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'condominos',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/condominos/condominos.module').then(m => m.CondominosModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'rateio',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/rateio/rateio.module').then(m => m.RateioModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'cotas-atraso',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/cotas-atraso/cotas-atraso.module').then(m => m.CotasAtrasoModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'pagamentos-recorrentes',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/pagamentos-recorrentes/pagamentos-recorrentes.module').then(m => m.PagamentosRecorrentesModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'funcionarios',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/funcionarios/funcionarios.module').then(m => m.FuncionariosModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'horarios-trabalho',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/horarios-trabalho/horarios-trabalho.module').then(m => m.HorariosTrabalhoModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'afastamentos',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/afastamentos/afastamentos.module').then(m => m.AfastamentosModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'acordos-andamento',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/acordos-andamento/acordos-andamento.module').then(m => m.AcordosAndamentoModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'outras-info-func',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/outras-info-relevantes/outras-info-relevantes.module').then(m => m.OutrasInfoRelevantesModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'folha-pagamento',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/folha-pagamento/folha-pagamento.module').then(m => m.FolhaPagamentoModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'ferias-gozadas',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/ferias-gozadas/ferias-gozadas.module').then(m => m.FeriasGozadasModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'ferias-programadas',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/ferias-programadas/ferias-programadas.module').then(m => m.FeriasProgramadasModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'dp-outras-info',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/dp-outras-info/dp-outras-info.module').then(m => m.DpOutrasInfoModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'outras-info-recebimento',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/recebimento-outras-info/recebimento-outras-info.module').then(m => m.RecebimentoOutrasInfoModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
  {
    path: 'outras-info-condominio',
    component: HeaderAndSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/condominio-outras-info/condominio-outras-info.module').then(m => m.CondominioOutrasInfoModule)
      },
    ],
    canActivate: [ForbiddenPageStatusInterceptor]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
