import { Observable } from 'rxjs';
import { AssuntoEnvioEmailDTO } from '../DTO/response/AssuntoEnvioEmailDTO';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class SendMessageService {
  private host: string = environment.apiContratacao.host;
  private methods = environment.apiContratacao.endpoints.methods.menu;

  constructor(
    private http: HttpClient,
  ) {}

  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  buscarMenuDescricao(): Observable<AssuntoEnvioEmailDTO[]> {
    const url = this.host + this.methods.buscarMenuDescricao;
    const options = { headers: this.getHeader() };
    return this.http.get<AssuntoEnvioEmailDTO[]>(url, options);
  }

  enviarMensagem(obj: any): Observable<any> {
    const url = this.host + this.methods.faleConosco;
    const headers = this.getHeader();
    return this.http.post<any>(url, obj, { headers });
  }
}
