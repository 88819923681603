import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class XmlService {

  public host = environment.apiPat.host;
  public methods = environment.apiContratacao.endpoints.methods;

  constructor(
    private http: HttpClient
  ) { }

  private getHeaders(){
    return new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  logaXML(uid: string, idPat: number){
    const parametros = new HttpParams()
      .set('uid', uid)
      .set('idPat', idPat.toString())

    return this.http.get(`${this.host + this.methods.xml.getConteudo}`,
      {headers: this.getHeaders(), params:parametros});
  }
}
