<div class="app-right-sidebar-card">
  <div [ngClass]="{
    '__is-active': this.showStatus,
    '__is-analise': !this.isAlert,
    '__is-alert': this.isAlert
   }"
   class="sidebar-card __is-analise ">
    <div class="sidebar-card_Container">
      <div class="card_Container_Icone">
        <i class="fas fa-exclamation-circle"></i>
      </div>
      <div class="card_Container_Content">
        <div class="Container_Content_Texto">
          {{this.descricaoStatus}}
        </div>
      </div>
    </div>
  </div>

  <div class="sidebar-card __is-status __is-active">
    <div class="sidebar-card_Container">
      <div class="card_Container_Icone">
        <i class="fas fa-exclamation-circle"></i>
      </div>
      <div class="card_Container_Content">
        <div class="Container_Content_Titulo">
          Status do preenchimento:
        </div>
        <div class="Container_Content_TipoStatus">
          <div class="Content_TipoStatus_Item __is-NaoPreenchido">
            <span>Não Preenchido</span>
            <i class="fas fa-pen"></i>
          </div>
          <div class="Content_TipoStatus_Item __is-Concluido">
            <span>Concluído</span>
            <i class="fas fa-check"></i>
          </div>
          <div class="Content_TipoStatus_Item __is-Analise">
            <span>Em análise\revisão</span>
            <i class="fas fa-sync-alt"></i>
          </div>
          <div class="Content_TipoStatus_Item __is-Pendente">
            <span>Pendente</span>
            <i class="fas fa-exclamation"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sidebar-card __is-instrucoes __is-active">
    <div class="sidebar-card_Container">
      <div class="card_Container_Icone">
        <i class="fas fa-exclamation-circle"></i>
      </div>
      <div class="card_Container_Content">
        <div class="Container_Content_Texto" [innerHTML]="instrucoes">
        </div>
      </div>
    </div>
  </div>
</div>