<div
  class="Card"
  [ngClass]="{ '__has-footer': hasFooter }"
>
  <div>
    <div class="Card_Content">
      <ng-content></ng-content>
    </div>

    <div class="Card_Footer">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>