import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { SidebarState } from '../components/sidebar/sidebar.state';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[sidebarOpen]'
})
export class SidebarOpenDirective implements OnDestroy {
  public subscription: Subscription;
  private classNameOpen: string = '__is-sidebar-open';
  private classNameClosed: string = '__is-sidebar-closed';

  constructor(
    public elementRef: ElementRef,
    public sidebarState: SidebarState,
  ) {
    this.subscription = this.addClassNameToElementWhenSidebarIsOpen();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private addClassNameToElementWhenSidebarIsOpen() {
    return this.sidebarState.getIsOpen()
      .subscribe(isOpen => {
        if(this.hasNodeReference())
          this.addOrRemoveClassName(isOpen);
        }
      );
  }

  private addOrRemoveClassName(sidebarOpen: boolean) {
    if(sidebarOpen) {
      this.elementRef.nativeElement.classList.add(this.classNameOpen);
      this.elementRef.nativeElement.classList.remove(this.classNameClosed);
    }
    else {
      this.elementRef.nativeElement.classList.remove(this.classNameOpen);
      this.elementRef.nativeElement.classList.add(this.classNameClosed);
    }
  }

  private hasNodeReference(): boolean {
    if(this.elementRef && this.elementRef.nativeElement)
      return true;
    else
      return false;
  }
}
