import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { MenuDTO } from 'src/app/shared/DTO/response/menuDTO';
import { StateService } from 'src/app/shared/services/state.service';

@Injectable()
export class SidebarService {

  private host: string = environment.apiContratacao.host;
  private methods = environment.apiContratacao.endpoints.methods.menu;

  constructor(
    private http: HttpClient,
    private stateService: StateService
  ) {}

  getFuncionalidades(): Observable <MenuDTO[]> {
    const url = this.host + this.methods.getFuncionalidades;
    const assinaturaID = this.getAssinaturaID();

    const queryParams = new HttpParams()
      .set('uidLead', assinaturaID);

    const options = { headers: this.headers, params: queryParams };

    return this.http.get<MenuDTO[]>(url, options);
  }

  private getAssinaturaID() {
    return JSON.parse(sessionStorage.getItem('usuarioLogado')).uidLead;
  }

  private get headers(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.stateService.getTokenApi()}`
    });
  }
}
