import { Component, OnInit, AfterViewChecked, AfterViewInit } from '@angular/core';
import * as xml2js from 'xml2js';
import { XmlService } from '../../services/xml.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LightBoxesService } from 'src/app/shared/components/lightboxes';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements AfterViewInit {

  public hasAnexos: boolean;
  public hasConteudo: boolean;
  public key: string;
  public json;
  public password: string;

  public escondeInfo: boolean = false;

  constructor(
    private lightBoxesService: LightBoxesService,
    private xmlService: XmlService,
    private activatedRouter: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRouter.queryParams.subscribe(params => {
      this.key = params.key
    })
  }


  ngAfterViewInit() {
    if (this.key) {
    this.logInPAT()
    }

  }

  logInPAT() {
    this.lightBoxesService.openForModalConfirmation(
      'text-input-with-confirmation', {
      title: 'Acesso Restrito',
      email: '',
      text: 'Para prosseguir, por favor informe o numero da PAT associada.'
    }, 0
    ).then(senha => {
      if (senha) {
        this.password = senha;

        const senhaXML: number = parseInt(senha)
        this.logXML(senhaXML)
      } else {
        this.router.navigate(['/'])
      }
    })
  }

  logXML(senhaXML) {
    //método de validação da senha
    this.xmlService.logaXML(this.key, senhaXML).subscribe(item => {
      if (item) {
        this.json = item;
        this.hasAnexos = this.json.anexos ? true : false;
        this.hasConteudo = this.json.conteudo != '{}' ? true : false;
        this.parseJSON(this.json.conteudo);
        
      }
    }, error => {
      this.lightBoxesService.open(
        'custom-generico',
        { title: 'Erro', text: "Número de PAT informado incorreto, por favor, verifique os dados e tente novamente" },
        6000,
      );
      this.logInPAT();
    })
  }

  //parseado na mão
  // teste = { 'name':'John', 'age':'30', 'car':null }

  enableContent() {
    if (this.hasAnexos) document.getElementById('tableAnexos').removeAttribute('hidden');
    if (this.hasConteudo) document.getElementById('tableConteudo').removeAttribute('hidden');
  }


  parseJSON(json: string) {

    document.getElementById('titlePAT').removeAttribute('hidden');
    this.enableContent();

    if (!this.json.anexos.length) {
      document.getElementById('tableAnexos').innerHTML += '<span> Não há anexos há exibir </span>';
    }
    else {
      for (const [key, value] of Object.entries(this.json)) {
        if (value && typeof value == 'object') {
          for (let v of Object.values(value)) {
            console.log(v)
            console.log(v.urlDownload)
            document.getElementById('tableAnexos').innerHTML += '<tr><td>' + '<a href="' + `${v.urlDownload}`+ '">' + `${v.nome}` + '</a>'  + '</tr></td>';
            // for (const [k, z] of Object.entries(v)) {
            //   document.getElementById('tableAnexos').innerHTML += '<tr><td>' + `<a href='${z}>'${k}</a>` + '<br>' + `${z}` + '</tr></td>'
            // }
          }
        }
      }
    }

    for (const [key, value] of Object.entries(JSON.parse(json.replace(/'/g, '"')))) {
      if (typeof value == 'object') {
        let [y, x] = [key, value];
        if (Array.isArray(x)) {
          document.getElementById('tableConteudo').innerHTML += '<tr><td>' + `${y}`;
          for (let i of x) {
            document.getElementById('tableConteudo').innerHTML += '<tr><td>' + `${i}`;
          }
        }
        else {
          document.getElementById('tableConteudo').innerHTML += '<tr><td>' + `${y}`;
          for (const [key, value] of Object.entries(x)) {
            document.getElementById('tableConteudo').innerHTML += '<tr><td>' + `${key}` + '<br>' + `${value}` + '</tr></td>'
          }
        }

      } else {
        document.getElementById('tableConteudo').innerHTML += '<tr><td>' + `${key}` + '<br>' + `${value}` + '</tr></td>'
      }
    }
  }
}