<div class="Lightbox">
  <div class="Popup Popup_ComunicacaoGenerica">
    <div class="Popup_Header">
      <div class="Popup_Header_Title">Titulo genérico</div>
    </div>
    
    <div class="Popup_Content">
      <div class="Popup_Content_MsgDefault">
        Aqui entrará qualquer texto
      </div>
    </div>

    <div class="Popup_Footer">
      <div class="Popup_Footer_Buttons">
        <ds-button full="true" type="Outline" (click)="close()">
          Não
        </ds-button>

        <ds-button full="true" (click)="confirmar()">
          Sim
        </ds-button>
      </div>
    </div>
  </div> 
</div>
