<div class="Modal ModalUserMenu" [ngClass]="{ '__is-open': isOpen }">
  <div class="Modal_Close">
    <i
      (click)="closeModal()"
      *ngIf="showCloseButton"
      class="fas fa-times"
    ></i>
  </div>

  <div class="Modal_Header">
    Menu
  </div>

  <div class="Modal_Content">
    <div class="Block">
      <div class="menu-content" *ngIf="showMenu">
        <div class="menu-item" (click)="openChatBotWindow()">
          <div class="menu-item__icon">
            <i class="fab fa-rocketchat"></i>
          </div>
  
          <div class="menu-item__label">
            Chat online
          </div>
        </div>

        <div class="menu-item">
          <div class="menu-item__icon">
            <i class="fab fa-whatsapp"></i>
          </div>
  
          <div class="menu-item__label">
            Atendimento via Whatsapp
          </div>
        </div>

        <div class="menu-item" (click)="handleSendMessage()">
          <div class="menu-item__icon">
            <i class="far fa-comments"></i>
          </div>
  
          <div class="menu-item__label">
            Enviar uma mensagem
          </div>
        </div>

        <div class="menu-item">
          <div class="menu-item-content">
            <div class="menu-item__label">
              Telefones úteis
            </div>
  
            <div class="menu-item__description">
              Encontre o telefone da central de atendimento
              que está procurando: 55 21 0000 0000
            </div>
          </div>
        </div>
  
        <div
          (click)="sairSimOuNao()"
          class="menu-item __is-danger"
        >
          <div class="menu-item__icon">
            <i class="apsa-icon-outline-logout"></i>
          </div>
  
          <div class="menu-item__label">
            Sair
          </div>
        </div>
      </div>

      <app-send-message
        *ngIf="showSendMessage"
        (onBack)="handleSendMessageBack()"
      ></app-send-message>
    </div>
  </div>
</div>
