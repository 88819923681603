import { Component, OnInit } from '@angular/core';
import { ChatBotFacade } from '../../facades/chat-bot.facade';

@Component({
  selector: 'app-central-de-atendimento',
  templateUrl: './central-de-atendimento.component.html',
  styleUrls: ['./central-de-atendimento.component.scss']
})
export class CentralDeAtendimentoComponent implements OnInit {
  public enviarMensagem: boolean = false;

  constructor(
    private chatBotFacade: ChatBotFacade
  ) { }

  ngOnInit(): void {
  }

  handleChatOnlineClick() {
    this.chatBotFacade.open();
  }

  handleEnviarUmaMensagemClick() {
    this.enviarMensagem = true;
  }

  handleAtendimentoBack() {
    this.enviarMensagem = false;
  }
}
