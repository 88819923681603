<aside
  class="SidebarComponent"
  [ngClass]="{
    '__is-close': isClosed,
    '__is-open': isOpen
  }"
>
  <div class="Content">
    <div class="Menu">
      <div class="Title">Implantação</div>

      <div
        class="CollapseMenu"
        [ngClass]="{'__is-open': isTestMenuOpen}"
      >
        <div
          class="CollapseMenuTitle"
          [ngClass]="{'__is-large-title': usuarioLogado.nomeCondominio.length > 28 }"
          (click)="isTestMenuOpen = !isTestMenuOpen"
        >
          <div class="TextAreaAndArrow">
            <div class="TextArea">
              {{usuarioLogado.nomeCondominio}}
            </div>
            
            <i class="fas fa-chevron-right"></i>
          </div>

          <div class="SubTextArea">
            {{usuarioLogado.contaCondominio}}
          </div>

        </div>

        <div class="CollapseMenuContent">
          <div
            class="InnerCollapse"
            *ngFor="let menu of menus$ | async"
            [id]="menu.codigoMenu"
            [ngClass]="{
              '__is-open': (menu.codigoMenu == codigoMenuAberto)
            }"
          >
            <div
              class="InnerCollapseTitle"
              (click)="toggleSubMenu(menu.codigoMenu)"
            >
              <div class="TextArea">{{ menu.descricao }}</div>

              <i class="Arrow fas fa-sort-down"></i>
            </div>

            <div
              class="InnerCollapseContent"
              *ngFor="let submenu of menu.funcionalidades"
            >
              <div
                class="MenuItem"
                (click)="setInstrucoes(submenu)"
                [ngClass]="{'__is-selected': this.router.url == submenu.rota}"
              >
                <i
                  class="fas"
                  [ngClass]="getSubmenuIconClass(submenu)"
                  [id]="submenu.rota"
                >
                </i>

                <div class="MenuItemTitle">
                  {{ submenu.descricao }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="CloseArea" (click)="toggleSidebarState()">
    <i class="fas fa-chevron-left"></i>
  </div>
</aside>
