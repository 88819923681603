<div class="loading-screen-wrapper" *ngIf="loading">
  <div class="loading-screen-icon">
    <div class="loading lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
