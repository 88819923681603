import {  Injectable } from '@angular/core';
import { SidebarService } from './sidebar.service';
import { SidebarState } from './sidebar.state';
import { Router } from '@angular/router';
import { FuncionalidadesMenuDTO, MenuDTO } from '../../DTO/response/menuDTO';
import { MenuBusiness } from '../../business/menu.business';

@Injectable()
export class SidebarFacade {

  constructor(
    private sidebarService: SidebarService,
    private sidebarState: SidebarState,
    private menuBusiness: MenuBusiness,
    private router: Router,
  ) { }

  setCurrentMenu(menu: FuncionalidadesMenuDTO) {
    this.sidebarState.setCurrentMenu(menu);
  }

  toggle() {
    this.sidebarState.toggle();
  }

  close() {
    this.sidebarState.close();
  }

  isOpen() {
    return this.sidebarState.isOpen();
  }

  isClosed() {
    return this.sidebarState.isClosed();
  }

  getAllMenus() {
    return this.sidebarState.getAllMenus();
  }

  getAllMenusValue() {
    return this.sidebarState.getAllMenusValue();
  }

  fetchAllMenusIfUnset() {
    if(this.sidebarState.isEmpty())
      this.fetchFuncionalidades();
  }

  fetchFuncionalidades() {
    this.sidebarState.setUpdating(true);

    this.sidebarService.getFuncionalidades()
    .subscribe(
      menus => this.handleSuccessfullFetch(menus),
      error => console.error(error),
      () => this.sidebarState.setUpdating(false),
    );
  }

  fetchFuncionalidadesAsync(): Promise<void> {
    return new Promise(resolve => {
      this.sidebarState.setUpdating(true);
  
      this.sidebarService.getFuncionalidades()
      .subscribe(
        menus => this.handleSuccessfullFetch(menus),
        error => console.error(error),
        () => {
          this.sidebarState.setUpdating(false)
          resolve();
        },
      );
    })
  }

  routeToNextStepOrHome() {
    this.menuBusiness.routeToNextStepOrHome();
  }

  resetState() {
    this.sidebarState.reset();
  }

  private handleSuccessfullFetch(menus: MenuDTO[]) {
    this.sidebarState.setAllMenus(menus);

    const allRoutes = [];
    
    //Lógica para apontar próxima rota
    for (let i = 0; i < menus.length; i++) {
      for (let f of menus[i].funcionalidades) {
        allRoutes.push(f.rota);
      }
    }

    let rotaAtual = allRoutes.findIndex(rota => rota == this.router.url);
    let proximaRota = allRoutes[rotaAtual + 1];
    sessionStorage.setItem('proximaRota', proximaRota); 
  }
}