<header class="ApplicationHeaderComponent">
  <div class="APSALogo">
    <a routerLink="/home">
      <img src="../../../../assets/logo_header.png">
    </a>
  </div>

  <div class="MobileBackButton">
    <i
      (click)="openSidebar()"
      class="fas fa-bars"
    ></i>
  </div>

  <!-- AQUI ABRE MENU USER MOBILE -->
  <div class="UserMenu" (click)="abrirModalPessoa()">
    <div class="UserIcon">
      <i class="apsa-icon-solid apsa-icon-solid-user"></i>
    </div>

    <div class="UserNameText">{{usuarioLogado.nome}}</div>
  </div>

  <app-dados-header></app-dados-header> 
  
</header>