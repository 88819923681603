<router-outlet></router-outlet>

<app-ligthboxes-default id="custom-generico" className="__is-open">
</app-ligthboxes-default>

<app-mural-cadastrado id="custom-mural-cadastrado" className="__is-open">
</app-mural-cadastrado>

<app-text-input-with-confirmation id="text-input-with-confirmation" className="__is-open">
</app-text-input-with-confirmation>

<app-image-with-toggle-and-ok-button id="image-with-toggle-and-ok-button" className="__is-open">
</app-image-with-toggle-and-ok-button>
