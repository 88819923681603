import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ChatBotFacade } from '../../facades/chat-bot.facade';
import { ModalService } from '../../services/modal.service';
import { LightBoxesService } from '../lightboxes';

const MODAL_NAME = 'user-menu';

@Component({
  selector: 'app-modal-user-menu',
  templateUrl: './modal-user-menu.component.html',
  styleUrls: ['./modal-user-menu.component.scss']
})
export class ModalUserMenuComponent implements OnInit {

  public showMenu: boolean = true;
  public showSendMessage: boolean = false;

  constructor(
    private modalService: ModalService,
    private lightBoxesService: LightBoxesService,
    private router: Router,
    private chatBotFacade: ChatBotFacade,
  ) { }

  ngOnInit(): void {
    this.modalService.add(MODAL_NAME);
  }

  closeModal() {
    this.modalService.close(MODAL_NAME);
  }

  handleSendMessage() {
    this.showMenu = false;
    this.showSendMessage = true;
  }

  handleSendMessageBack() {
    this.showMenu = true;
    this.showSendMessage = false;
  }

  openChatBotWindow() {
    this.chatBotFacade.open();
  }

  get isOpen() {
    return this.modalService.isOpen(MODAL_NAME);
  }

  get showCloseButton(): boolean {
    return this.showMenu;
  }

  async sairSimOuNao() {
    const userChoice = await this.openExitConfirmationPopup();

    if(userChoice)
      this.sair();
  }

  private sair() {
    sessionStorage.clear();
    this.router.navigate(['/']);
    return;
  }

  private openExitConfirmationPopup(): Promise<boolean> {
    return this.openYesOrNoPopup('Sair', 'Tem certeza que quer sair?');
  }

  private openYesOrNoPopup(title: string, text: string) {
    const id = 'custom-mural-cadastrado';

    return this.lightBoxesService.open(id, { title, text }, 0);
  }
}
