import { Injectable, ElementRef } from "@angular/core";
import { DefaultLightBoxesComponent } from './default/default.component';

@Injectable({ providedIn: "root" })
export class LightBoxesService {
    private modals: any[] = [];
    private copyModals: any[] = [];

    add(modal: any) {
        this.copyModals = [...this.modals]
        this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string, mensagem: { title: string, text: string }, time: number) {
        let modal = this.modals.find(x => x.id === id);

        if (!modal) {
            modal = this.copyModals.find(x => x.id === id)
        }

        return modal.open(mensagem, time);
    }

    openClienteContratacao(id: string, mensagem: { title: string, nome: string, cpf: string }, time: number) {
        let modal = this.modals.find(x => x.id === id);

        if (!modal) {
            modal = this.copyModals.find(x => x.id === id)
        }

        return modal.open(mensagem, time);
    }

    openImageModal(id: string, mensagem: { title: string, imgPath: string, toggleLabel: string, url: string }, time: number) {
        let modal = this.modals.find(x => x.id === id);

        if (!modal) {
            modal = this.copyModals.find(x => x.id === id)
        }

        return modal.open(mensagem, time);
    }


    openForModalConfirmation(id: string, mensagem: { title: string, email: string, text: string }, time: number) {
        let modal = this.modals.find(x => x.id === id);

        if (!modal) {
            modal = this.copyModals.find(x => x.id === id)
        }

        return modal.open(mensagem, time);
    }

    close(id: string) {
        // close modal specified by id
        const modal = this.modals.find(x => x.id === id);
        modal.close();
    }
}
