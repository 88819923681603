import { Component, OnInit, PLATFORM_ID, Inject  } from '@angular/core';
import { LightBoxesService } from '../lightboxes';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { SidebarState } from '../sidebar/sidebar.state';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public usuarioLogado;
  public isBrowser;

  constructor(
    private lightBoxesService: LightBoxesService,
    private router: Router,
    private sidebarState: SidebarState,
    public modalService: ModalService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
   }

  ngOnInit(): void {
    this.usuarioLogado = JSON.parse(sessionStorage.getItem('usuarioLogado'));
  }

  abrirModalPessoa() {
    if (window.innerWidth < 800) {
      this.abreModalPessoaMobile()
    } else {
      this.abreModalPessoaDesktop('.Header_Atalho_Perfil')
    }
  }

  abreModalPessoaMobile(){
    this.modalService.open('user-menu');
  }

  abreModalPessoaDesktop(input){
    this.closeAllComponentsHeader(input);
    var container = document.querySelectorAll(input);

    for (var i = 0; i < container.length; i++) {
      if (container[i].classList.contains('__is-open')) {
        container[i].classList.remove('__is-open');
      } else if (!container[i].classList.contains('__is-open')) {
        container[i].classList.add('__is-open');
      }
    }
  }

  closeAllComponentsHeader(input) {
    if (input != '.Header_Atalho_Perfil')
      this.closeComponent('.Header_Atalho_Perfil');

    if (input != '.Header_Atalho_Acessibilidade')
      this.closeComponent('.Header_Atalho_Acessibilidade');

    if (input != '.Header_Atalho_Duvidas')
      this.closeComponent('.Header_Atalho_Duvidas');

    if (input != '.Header_Atalho_CentralAtendimento')
      this.closeComponent('.Header_Atalho_CentralAtendimento');

    if (input != '.Header_Atalho_Notificacoes')
      this.closeComponent('.Header_Atalho_Notificacoes');

    if (input != '.Header_Shortcut') this.closeComponent('.Header_Shortcut');
  }

  closeComponent(input) {
    var container = document.querySelectorAll(input);
    for (var i = 0; i < container.length; i++) {
      container[i].classList.remove('__is-open');
    }
  }

  openSidebar() {
    this.sidebarState.open();
  }
}
