import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { MenuBusiness } from '../business/menu.business';

@Injectable({ providedIn: 'root' })
export class ForbiddenPageStatusInterceptor implements CanActivate {

  constructor(
    public menuBusiness: MenuBusiness,
  ) { }
  
  canActivate(route: ActivatedRouteSnapshot): boolean {
    if(this.menuBusiness.isForbiddenAccess('/' + route.url.toString())) {
      this.menuBusiness.handleForbiddenAccess();

      return false;
    }
    else {
      return true;
    }
  }
}
