<div class="DesignSystem_CheckboxComponent">
  <input [id]="id" type="checkbox" [value]="checkboxValue">

  <div
    class="CustomCheckbox"
    (click)="toggleValue()"
    [ngClass]="{'__is-checked': checkboxValue}"
  >
    <div class="CheckedIconContainer">
      <i class="fas fa-check"></i>
    </div>
  </div>

  <label [for]="id" (click)="toggleValue()">
    {{ label }}
  </label>
</div>