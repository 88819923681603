<div class="Lightbox">
  <div class="Popup Popup_MuralCadastrado">
    <div class="Popup_Header">
      <div class="Popup_Header_Title">Pronto!</div>
    </div>
    <div class="Popup_Content">
      <div class="Popup_Content_MsgDefault">
        Seu recado já está no mural.
      </div>
    </div>
    <div class="Popup_Footer">
      <div class="Popup_Footer_Buttons">
        <ds-button full="true" (click)="close()">Ok</ds-button>
      </div>
    </div>
  </div>
</div>
