import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-right-sidebar-card',
  templateUrl: './right-sidebar-card.component.html',
  styleUrls: ['./right-sidebar-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RightSidebarCardComponent implements OnInit {
  @Input('p-title') title: string;
  @Input('p-icon') icon: string;
  instrucoes = sessionStorage.getItem('instrucoes')
  showStatus = false;
  isAlert = false;
  descricaoStatus = sessionStorage.getItem('descricaoStatus')

  constructor() { }

  ngOnInit(): void {
    this.instrucoes = "<b>Instruções</b>:<br>" + sessionStorage.getItem('instrucoes')
    
    this.validarStatus();
  }

  validarStatus(){
    var status = parseInt(sessionStorage.getItem('status'));
    this.showStatus = (status != 308 && status != 0)
    if(status == 306){
      this.isAlert = true;
      this.descricaoStatus = sessionStorage.getItem('descricaoStatus')
    } else{
      this.isAlert = false;
      this.descricaoStatus = "Esta etapa está em revisão pela equipe de implantação e não é possível alterar os dados durante este processo. Aguarde até a finalização da revisão."
    }
  }


  
}
