import { 
  trigger,
  transition,
  style,
  query as importedQuery,
  group,
  animate
} from '@angular/animations';

const ANIMATION_DURATION: string = '.35s';

const query = (style, animate, optional = { optional: true }) =>
  importedQuery(style, animate, optional)

export const moduleChangeAnimation = trigger('routeAnimations', [
  transition('* <=> *', [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        width: '100%',
        pointerEvents: 'none',
      })
    ]),
    query(':enter', [
      style({ left: '-100%' })
    ]),
    query(':leave', [
      style({ left: 0 }),
    ]),
    group([
      query(':leave', [
        animate(`${ANIMATION_DURATION} ease-out`, style({
            left: '100%'
          })
        )
      ]),
      query(':enter', [
        animate(`${ANIMATION_DURATION} ease-out`, style({
            left: 0,
          })
        )
      ]),
    ]),
  ]),
]);