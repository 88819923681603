import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarFacade } from './components/sidebar/sidebar.facade';
import { SidebarService } from './components/sidebar/sidebar.service';
import { HeaderComponent } from './components/header/header.component';
import { DesignSystemModule } from '../design-system/design-system.module';
import { RouterModule } from '@angular/router';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { RightSidebarCardComponent } from './components/right-sidebar-card/right-sidebar-card.component';
import { FormularioGenericoComponent } from './components/formulario-generico/formulario-generico.component';
import {
  DefaultLightBoxesComponent,
  MuralCadastradoComponent,
  ImageWithToggleAndOkButton,
  TextInputWithConfirmationComponent,
} from './components/lightboxes/index';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingScreenInterceptor } from './interceptors/loading-screen.interceptor';

import { SidebarOpenDirective } from './directives/sidebar-open.directive';
import { PasswordToggleDirective } from './directives/password-toggle.directive';
import { DadosHeaderComponent } from './components/dados-header/dados-header.component';

import { ModalUserMenuComponent } from './components/modal-user-menu/modal-user-menu.component';
import { MenuBusiness } from './business/menu.business';
import { SendMessageComponent } from './components/send-message/send-message.component';
import { CentralDeAtendimentoComponent } from './components/central-de-atendimento/central-de-atendimento.component';
import { ChatBotFacade } from './facades/chat-bot.facade';

const lightBoxesComponents = [
  DefaultLightBoxesComponent,
  MuralCadastradoComponent,
  ImageWithToggleAndOkButton,
  TextInputWithConfirmationComponent,
  
];

@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    RightSidebarComponent,
    RightSidebarCardComponent,
    ...lightBoxesComponents,
    LoadingScreenComponent,
    PasswordToggleDirective,
    SidebarOpenDirective,
    FormularioGenericoComponent,
    DadosHeaderComponent,
    ModalUserMenuComponent,
    SendMessageComponent,
    CentralDeAtendimentoComponent,
  ],
  imports: [
    CommonModule,
    DesignSystemModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  exports: [
    SidebarComponent,
    HeaderComponent,
    RightSidebarComponent,
    RightSidebarCardComponent,
    ...lightBoxesComponents,
    LoadingScreenComponent,
    PasswordToggleDirective,
    FormularioGenericoComponent,
    DadosHeaderComponent,
    SidebarOpenDirective,
    ModalUserMenuComponent,
    SendMessageComponent,
    CentralDeAtendimentoComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    },
    MenuBusiness,
    SidebarFacade,
    SidebarService,
    ChatBotFacade,
  ],
})
export class SharedModule { }
