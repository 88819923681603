export const environment = {
  production: false,
  isMock: true,
  apiContratacao: {
    host: '//cndcontratacaoapi.hmg.marlin.com.br/',
    acesso: {
      login: 'condominiodigital@apsa',
      senha: '83892d5910ea',
    },
    endpoints: {
      token: 'api/Login',
      methods: {
        login: {
          logar: 'api/UsuarioImplantacao/login',
          esqueciASenha: 'api/UsuarioImplantacao/esqueciMinhaSenha',
          redefinirSenha: 'api/UsuarioImplantacao/redefinirSenha',
        },
        menu: {
          getFuncionalidades: 'api/Funcionalidade',
          buscarMenuDescricao: 'api/Funcionalidade/buscarMenuDescricao',
          faleConosco: 'api/Funcionalidade/faleConosco',
        },
        xml: {
          getConteudo: '/v1/pat/getConteudo',
        },
        dadosCondominio: {
          implantacao: 'getInfoCondominio',
        },
        condicoesContratuais: {
          getDadosContratuais: 'getInfoContrato',
        },
        cpf: {
          getCpfParceiro: 'api/Contratacao/getCpfParceiro',
        },
      },
    },
  },
  apiPat: {
    host: '//apsaapipat.hmg.marlin.com.br/api',
    //host: 'https://areaexclusiva.apsa.com.br/pat-api/api',
    endpoints: {
      methods: {
        patGenerica: '/v1/pat/generica',
      },
    },
  },
};
