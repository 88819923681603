import { 
  trigger,
  transition,
  style,
  query as importedQuery,
  group,
  animateChild,
  animate
} from '@angular/animations';

const query = (style, animate, optional = { optional: true }) =>
  importedQuery(style, animate, optional)

export const slideInAnimation = trigger('routeAnimations', [
  transition('LoginPage <=> *', [
    style({ position: 'relative'}),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        pointerEvents: 'none',
      })
    ]),
    query(':enter', [
      style({ right: '-100%', opacity: '50%' })
    ]),
    query(':leave', [
      style({ opacity: '100%' }),
    ]),
    group([
      // Required to remove page blink:
      query('router-outlet ~ *', [animate('1ms', style({}))]),
      query(':leave', [
        animate('.5s ease-out', style({
            right: '100%',
            opacity: '0%',
          })
        )
      ]),
      query(':enter', [
        animate('.5s ease-out', style({
            right: '0%',
            opacity: '100%',
          })
        )
      ])
    ]),
  ]),
]);