import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ElementRef,
  OnDestroy
} from "@angular/core";


import { LightBoxesService } from "../lightboxes.services";

@Component({
  selector: 'app-ligthboxes-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultLightBoxesComponent implements OnInit {

  @Input() id: string;
  @Input() className: string;

  private element: any;
  private promiseResolveFunction: () => void = null;

  constructor(private service: LightBoxesService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener("click", el => {
      if (el.target.className === "Block_Popup_Lateral") {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.service.add(this);
  }

  ngOnDestroy(): void {
    this.service.remove(this.id);
    this.element.remove();
  }

  open(mensagem: { title: string, text: string }, time: number = 1000): Promise<void> {
    const n = this.className;
    const a = this.element;
    this.element.firstChild.classList.add(this.className);
    this.element.getElementsByClassName('Popup_Header_Title')[0].innerHTML = mensagem.title;
    this.element.getElementsByClassName('Popup_Content_MsgDefault')[0].innerHTML = mensagem.text;

    return new Promise(resolve => {
      this.promiseResolveFunction = resolve;

      setTimeout(() => {
        a.firstChild.classList.remove(n);
        this.resolvePromise();
      }, time);
    });
  }

  close(): void {
    this.element.firstChild.classList.remove(this.className);
    this.resolvePromise();
  }

  private resolvePromise() {
    if(this.promiseResolveFunction) {
      this.promiseResolveFunction();
      this.promiseResolveFunction = null;
    }
  }
}
