<div class="Page PageFormularioGenerico">
  <div class="PageFormularioGenerico_ConteudoPrincipal">
    <div class="PageFormularioGenerico_ConteudoPrincipal_Container">
      <div class="ConteudoPrincipal_Container_Form">
        <div class="TituloPrincipal">
          {{titulo}}
        </div>
        <form [formGroup]="form">
          <div class="Container_Form_NaoHaDados Formgroup" *ngIf="botaoNaoSeAplica">
            <div class="Form_NaoHaDados_Texto Formgroup_Label">
              <b>Não se aplica</b> (Selecione esta opção se você não possui dados para informar.)
            </div>
            <div class="Formgroup_Toggle">
              <div class="switch__container">
                <input id="switch-shadow" class="switch switch--shadow" (click)='naoSeAplicaToggle()' type="checkbox"
                  formControlName="naoSeAplica" />
                <label for="switch-shadow"></label>
              </div>
            </div>
          </div>

          <div *ngIf="!desabilitado">
            <div class="Container_Form_OrientacoesGestor Formgroup">
              <div class="Form_OrientacoesGestor_Titulo Formgroup_Label">
                <b>Orientações do Gestor</b>
              </div>

              <div class="Form_OrientacoesGestor_Textarea Formgroup_Textarea">
                <textarea
                  formControlName="orientacoesGestor"
                  placeholder="Escreva aqui"
                  rows="5"
                ></textarea>
              </div>
            </div>

            <div class="Container_Form_Planilha Formgroup" *ngIf="possuiPlanilha">
              <div class="Formgroup_Label">
                <b>Planilha de Modelo</b> (Download de modelo e upload de preenchimento)
              </div>

              <div class="Form_Planilha_Buttons Formgroup_Button">
                <div
                  class="Formgroup_Button_ItemIcone __is-disable"
                  *ngIf="pdf"
                  (click)='downloadPDF()'
                >
                  <div class="Button_ItemIcone_Icone">
                    <i class="fas fa-file-pdf"></i>
                  </div>

                  <span class="Button_ItemIcone_Name">Em PDF</span>
                </div>

                <div class="Formgroup_Button_ItemIcone" *ngIf="pdf">
                  <div class="Button_ItemIcone_Icone">
                    <i class="fas fa-file-pdf"></i>
                  </div>

                  <span class="Button_ItemIcone_Name">Em PDF</span>
                </div>

                <div
                  class="Formgroup_Button_ItemIcone"
                  *ngIf="xlsx"
                  (click)='downloadXLSX()'
                >
                  <div class="Button_ItemIcone_Icone">
                    <i class="fas fa-file-excel"></i>
                  </div>

                  <span class="Button_ItemIcone_Name">Em Excel</span>
                </div>
              </div>
            </div>
            
            <div class="Container_Form_UploadAnexos Formgroup">
              <div class="Form_UploadAnexos_Titulo Formgroup_Label">
                <b>Anexos</b> (Uploads)
              </div>

              <div class="Form_UploadAnexos_InputDiv Formgroup_Anexo">
                <label
                  for="UploadAnexos_InputDiv_Item"
                  (click)="anexos.click()"
                  class="Formgroup_Anexo_Button"
                  *ngIf="!anexosForm.nome"
                >
                  Abrir
                </label>

                <label
                  for="UploadAnexos_InputDiv_Item"
                  class="UploadAnexos_InputDiv_InputCamp Formgroup_Anexo_ButtonInputCamp"
                >
                  {{(anexosForm.nome)? anexosForm.nome:'Localizar arquivo no seu computador...'}}
                </label>

                <input type="file" #anexos id="Formgroup_Anexo_InputFile" (change)="onFileChange($event)" [formControl]="fileValue">
              </div>

              <div class="Form_UploadAnexos_InputDiv Formgroup_Anexo __is-active">
                <label
                  class="Formgroup_Anexo_Button"
                  *ngIf="anexosForm.nome"
                  (click)='removeAnexo()'
                >
                  Excluir anexo
                </label>
              </div>
            </div>
          </div>

          <div class="SubmitButtonContainer">
            <ds-button (click)="enviar()">
              Enviar
            </ds-button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <app-right-sidebar>
    <app-right-sidebar-card>
      Teste
    </app-right-sidebar-card>
  </app-right-sidebar>
</div>
