import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanilhaModeloDTO } from '../../DTO/PlanilhaModelo.DTO';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AnexosFormDTO } from '../../DTO/anexosFormDTO';
import { formatWithOptions } from 'util';
import { LightBoxesService } from '../lightboxes';


@Component({
  selector: 'app-formulario-generico',
  templateUrl: './formulario-generico.component.html',
  styleUrls: ['./formulario-generico.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormularioGenericoComponent implements OnInit {

  @Input() possuiPlanilha: boolean;
  @Input() planilha: Array<PlanilhaModeloDTO>;
  @Output() formEmitter = new EventEmitter();

  public pdf: PlanilhaModeloDTO;
  public xlsx: PlanilhaModeloDTO;
  public form: FormGroup
  public anexosForm: AnexosFormDTO = new AnexosFormDTO();
  public fileValue = new FormControl(null)

  public botaoNaoSeAplica: boolean;
  public toggleForm: boolean;

  public desabilitado: boolean = false;
  public rejeitado: boolean = false;

  public titulo: string;

  public arrayTiposNegados = ['zip', 'rar', 'mp3', 'mp4', 'wav', 'mpeg-4', 'avi', 'wmv', 'wma', 'flac', 'exe'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private lightBoxesService: LightBoxesService
  ) {
    activatedRoute.params.subscribe(val => {
      // this.ngOnInit();
    })
  }

  ngOnInit(): void {
    const naoSeAplica = JSON.parse(sessionStorage.getItem('botaoNaoSeAplica'))
    if (naoSeAplica) {
      this.botaoNaoSeAplica = true;
    };

    this.planilha && this.planilha.forEach(indicePlanilha => {
      const ext: string = indicePlanilha.extensao;
      if (ext == 'pdf') this.pdf = indicePlanilha;
      if (ext == 'xlsx') this.xlsx = indicePlanilha;
    })
    this.createForm();

    this.verificaRejeitado();

    this.titulo = JSON.parse(sessionStorage.getItem('submenu')).descricao;
  }

  verificaRejeitado() {
    const descricaoRejeitado = JSON.parse(sessionStorage.getItem('submenu')).descricaoStatus;

    if (descricaoRejeitado != null) {
      this.rejeitado = true;
      this.limpaValidators();
    }

  }

  limpaValidators(){
    Object.keys(this.formControls).forEach((key,value) => {
      this.form.get(key).clearValidators();
    })
    this.form.updateValueAndValidity();

  }

  createForm() {
    this.form = this.formBuilder.group({
      naoSeAplica: [false],
      orientacoesGestor: ['', Validators.required]
    })
  }

  get formControls() {
    return this.form.controls;
  }

  downloadPDF() {
    window.open(this.pdf.urlDownload, '_blank')
  }

  downloadXLSX() {
    window.open(this.xlsx.urlDownload, '_blank')
  }

  naoSeAplicaToggle() {
    const valorToggle = this.formControls.naoSeAplica.value;
    if(valorToggle == false) {
      this.formControls.orientacoesGestor.patchValue('');
      this.formControls.orientacoesGestor.disable();
      this.desabilitado = true;
    }
    if(valorToggle == true) {
      this.formControls.orientacoesGestor.enable();
      this.desabilitado = false;
    }
  }

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      let nameArray = file.name.split('.');
      let ext = nameArray[nameArray.length - 1];
      
      if(this.arrayTiposNegados.includes(ext)){
        this.erroTipoUpload(ext)
      } else {
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.anexosForm.arquivo = reader.result.toString().split(',')[1];
          this.anexosForm.nome = file.name;
          this.fileValue.setValue(null)
        }
      }

    }
  }

  erroTipoUpload(ext: string) {
    this.lightBoxesService.open(
      'custom-generico', {
        title: 'Atenção',
        text: `O arquivo anexado não pode ser do formato "${ext}". Por favor tente novamente.`
      }, 5000
    )
  }

  removeAnexo(){
    this.anexosForm = new AnexosFormDTO()
  }

  enviar() {  
    if(this.formControls.naoSeAplica.value){
      this.formEmitter.emit('NÃO_SE_APLICA');
    } else if(!this.formControls.naoSeAplica.value){
      console.log(this.anexosForm)
      if(this.formControls.orientacoesGestor.valid || (this.anexosForm.arquivo && this.rejeitado)){
        let obj = this.form.value;
        obj.naoSeAplica = obj.naoSeAplica ? "Sim" : "Não";
        this.botaoNaoSeAplica? null : delete obj.naoSeAplica;
        this.formEmitter.emit([this.form.value, this.anexosForm]);
      } else {
        let mensagem = 'É necessário preencher as orientações, por favor tente novamente.';
        this.rejeitado? mensagem = 'É necessário preencher as orientações ou anexar um arquivo, por favor tente novamente.': null;
        this.lightBoxesService.open(
          'custom-generico', {
            title: 'Atenção',
            text: mensagem
          }, 5000
        )
      }
    }
  }
}